$(function() {
	// menu
	$('.close-menu').on('click', function(e) {
		e.preventDefault();
		$('.menu-container').fadeOut('fast');
	});
	$('.menu-trigger').on('click', function(e) {
		e.preventDefault();
		$('.menu-container').fadeIn('fast');
	});


	// Blocks slide

	$('.medics-panel .medic').slice(2, 1000).hide();


	$('.medics-panel .arrow-left').on('click', function(e) {
		e.preventDefault();
		$('.medics-panel .panel-body').css('height', $('.medics-panel .panel-body').outerHeight());
		var _show = $('.medics-panel .medic:visible:first').prevAll().slice(0,2);

		if (_show.length == 1) {
			_show.push($('.medics-panel .medic:last'));
		}
		if (_show.length == 0) {
			_show.push($('.medics-panel .medic:last'));
			_show.push($('.medics-panel .medic:last').prev());
		}

		$('.medics-panel .medic:visible').fadeOut('fast', function() {
			_show.each(function() {
				$(this).fadeIn('fast');
			});
		});
	});

	$('.medics-panel .arrow-right').on('click', function(e) {
		e.preventDefault();
		$('.medics-panel .panel-body').css('height', $('.medics-panel .panel-body').outerHeight());
		var _show = $('.medics-panel .medic:visible:last').nextAll().slice(0, 2);

		if (_show.length == 1) {
			_show.push($('.medics-panel .medic:first'));
		}
		if (_show.length == 0) {
			_show.push($('.medics-panel .medic:first'));
			_show.push($('.medics-panel .medic:first').next());
		}

		$('.medics-panel .medic:visible').fadeOut('fast', function() {
			_show.each(function() {
				$(this).fadeIn('fast');
			});
		});
	});

	$('.fields-panel .field').slice(2, 1000).hide();


	$('.fields-panel .arrow-left').on('click', function(e) {
		e.preventDefault();
		$('.fields-panel .panel-body').css('height', $('.fields-panel .panel-body').outerHeight());
		var _show = $('.fields-panel .field:visible:first').prevAll().slice(0,2);

		if (_show.length == 1) {
			_show.push($('.fields-panel .field:last'));
		}
		if (_show.length == 0) {
			_show.push($('.fields-panel .field:last'));
			_show.push($('.fields-panel .field:last').prev());
		}

		$('.fields-panel .field:visible').fadeOut('fast', function() {
			_show.each(function() {
				$(this).fadeIn('fast');
			});
		});
	});

	$('.fields-panel .arrow-right').on('click', function(e) {
		e.preventDefault();
		$('.fields-panel .panel-body').css('height', $('.fields-panel .panel-body').outerHeight());
		var _show = $('.fields-panel .field:visible:last').nextAll().slice(0, 2);

		if (_show.length == 1) {
			_show.push($('.fields-panel .field:first'));
		}
		if (_show.length == 0) {
			_show.push($('.fields-panel .field:first'));
			_show.push($('.fields-panel .field:first').next());
		}

		$('.fields-panel .field:visible').fadeOut('fast', function() {
			_show.each(function() {
				$(this).fadeIn('fast');
			});
		});
	});


	$('.field-panel .open').on('click', function(e) {
		e.preventDefault();

		_panel = $(this).closest('.panel');

		_panel.closest('.row').find('.panel').not(_panel).find('.panel-body').each(function() {
			$(this).slideUp('fast');
			$(this).closest('.panel').removeClass('visible');
		});
		_panel.find('.panel-body').slideDown('fast');
		_panel.addClass('visible');
	});

	$('.field-panel .collapse').on('click', function(e) {
		e.preventDefault();

		_panel = $(this).closest('.panel');

		_panel.find('.panel-body').slideUp('fast');
		_panel.removeClass('visible');
	});


	// About us slider
	$('.rslides').responsiveSlides({
		  auto: true,             // Boolean: Animate automatically, true or false
		  speed: 500,            // Integer: Speed of the transition, in milliseconds
		  timeout: 4000,          // Integer: Time between slide transitions, in milliseconds
		  pager: false,           // Boolean: Show pager, true or false
		  nav: true,             // Boolean: Show navigation, true or false
		  random: false,          // Boolean: Randomize the order of the slides, true or false
		  pause: true,           // Boolean: Pause on hover, true or false
		  pauseControls: true,    // Boolean: Pause when hovering controls, true or false
		  prevText: "Previous",   // String: Text for the "previous" button
		  nextText: "Next",       // String: Text for the "next" button
		  maxwidth: "",           // Integer: Max-width of the slideshow, in pixels
		  navContainer: "",       // Selector: Where controls should be appended to, default is after the 'ul'
		  manualControls: "",     // Selector: Declare custom pager navigation
		  namespace: "rslides",   // String: Change the default namespace used
		  before: function(){},   // Function: Before callback
		  after: function(){}     // Function: After callback
});
});